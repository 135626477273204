import { useCallback } from "react";
import { getAllPlatforms } from "../core/platform/repository/platformRepo";
import { setPlatforms } from "../store/slices/global";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";

export interface UseAllPlatforms {
  get: () => void;
  list: string[];
  notIn: (nin: string[]) => string[];
}

export const useAllPlatforms = (): UseAllPlatforms => {
  const platforms = useAppSelector((state) => state.global.platforms);
  const dispatch = useAppDispatch();
  let loading = false;

  const getAll = () => {
    if (["loading"].includes(platforms.status)) return;
    if (loading) return;
    loading = true;

    dispatch(setPlatforms({ ...platforms, status: "loading" }));

    getAllPlatforms()
      .then((response) => {
        dispatch(
          setPlatforms({
            ...platforms,
            status: "succeeded",
            data: response.data,
          })
        );
      })
      .catch((error) => {
        dispatch(setPlatforms({ ...platforms, status: "failed", error }));
      })
      .finally(() => {
        loading = false;
      });
  };

  const notIn = useCallback(
    (nin: string[]): string[] => {
      return platforms.data.filter((platform) => !nin.includes(platform));
    },
    [platforms]
  );

  return {
    get: getAll,
    list: platforms.data,
    notIn,
  };
};
