import i18n, { TFunction } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { enLocale } from './en';
import { esLocale } from './es';
import { ptLocale } from './pt';

import {
  enLocaleEmptyState,
  enLocaleMenu,
  enLocaleNotification,
  enLocaleTopBar,
  enLocaleWidget,
  esLocaleEmptyState,
  esLocaleMenu,
  esLocaleNotification,
  esLocaleTopBar,
  esLocaleWidget,
  ptLocaleEmptyState,
  ptLocaleMenu,
  ptLocaleNotification,
  ptLocaleTopBar,
  ptLocaleWidget,
} from '@foodology-co/alejandria';

export const i18nConfig = i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next);
const initI18n = async (): Promise<TFunction> =>
  i18nConfig.init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      es: {
        translation: {
          ...esLocale,
          ...esLocaleWidget,
          ...esLocaleTopBar,
          ...esLocaleNotification,
          ...esLocaleEmptyState,
          ...esLocaleMenu,
        },
      },
      pt: {
        translation: {
          ...ptLocale,
          ...ptLocaleWidget,
          ...ptLocaleTopBar,
          ...ptLocaleNotification,
          ...ptLocaleEmptyState,
          ...ptLocaleMenu,
        },
      },
      en: {
        translation: {
          ...enLocale,
          ...enLocaleWidget,
          ...enLocaleTopBar,
          ...enLocaleNotification,
          ...enLocaleEmptyState,
          ...enLocaleMenu,
        },
      },
    },
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default initI18n;
