import { useCallback } from 'react';
import { BrandSlice } from '../core/brand/entities/Brand';
import { getAllBrands } from '../core/brand/repository/brandRepo';
import { setBrands } from '../store/slices/global';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

export interface UseAllBrands {
  get: () => void;
  byBrandId: (brandId: string) => BrandSlice | undefined;
  byCountry: (country: string) => BrandSlice[];
  list: BrandSlice[];
}

export const useAllBrands = (): UseAllBrands => {
  const brands = useAppSelector((state) => state.global.brands);
  const dispatch = useAppDispatch();

  let loading = false;
  const getAll = () => {
    if (['loading'].includes(brands.status)) return;
    if (loading) return;
    loading = true;

    dispatch(setBrands({ ...brands, status: 'loading' }));

    getAllBrands()
      .then((response) => {
        dispatch(
          setBrands({
            ...brands,
            status: 'succeeded',
            data: response.map(
              (brand) =>
                ({
                  id: brand.id ?? '',
                  brandId: brand.brandId,
                  countries: brand.countries,
                  name: brand.name,
                } as BrandSlice),
            ),
          }),
        );
      })
      .catch((error) => {
        dispatch(setBrands({ ...brands, status: 'failed', error }));
      })
      .finally(() => {
        loading = false;
      });
  };

  const getByBrandId = useCallback(
    (brandId: string) => {
      return brands.data.find((brand) => brand.brandId === brandId);
    },
    [brands],
  );

  const getByCountry = useCallback(
    (country: string) => {
      return brands.data.filter((brand) => (country ? brand.countries.includes(country) : true));
    },
    [brands],
  );

  return {
    get: getAll,
    byBrandId: getByBrandId,
    byCountry: getByCountry,
    list: brands.data,
  };
};
