import Session from '../routes/components/Session/models/session';
import SessionService from '../routes/components/Session/services/session.service';

export enum ApiVersion {
  API = '/api/',
  V1 = '/api/v1/',
}

export enum HttpMethod {
  POST = 'POST',
  GET = 'GET',
}

const getHeaders = (session: Session, url: string) => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    'x-user-id': session.userId,
  };

  headers['x-auth-token'] = session.loginToken;

  if (!url.includes(process.env.REACT_APP_KITCHEN_DISPLAY_URL ?? '')) {
    headers['x-token'] = session.loginToken;
    headers['kos-accountid'] = '0';
    headers['Authorization'] = `Bearer ${session.authorization}`;
    headers['Kos-companyid'] = session.companyId;
  }

  return headers;
};

export const http = async (url: string, method: HttpMethod, body?: any): Promise<Response> => {
  const session = SessionService.getSession();
  const response = await fetch(url, {
    method,
    headers: getHeaders(session, url),
    body: body ? JSON.stringify(body) : undefined,
  });
  if (!response.ok) {
    throw new Error(`Error on ${url}`);
  }
  return response;
};

export interface ObjectParams {
  [key: string]: string;
}

export const getHttp = async (
  baseUrl: string | null,
  apiVersion: ApiVersion,
  path: string,
  params: ObjectParams = {},
  extraParams?: string
) => {
  const url = `${baseUrl}${apiVersion}${path}`;
  const extra = Object.keys(params).map((key) => `${key}=${params[key]}`);
  extraParams && extra.push(extraParams);
  return await http(`${url}?${extra.join("&")}`, HttpMethod.GET);
};

export const postHttp = async (
  baseUrl: string | null,
  apiVersion: ApiVersion,
  path: string,
  body?: any,
) => {
  return await http(`${baseUrl}${apiVersion}${path}`, HttpMethod.POST, body);
};
