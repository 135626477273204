import {
  categorization,
  commons,
  cpStationOptoins,
  info,
  Locales,
  machineList,
  measureUnits,
  menuCreator,
  menuType,
  menuUploader,
  productForm,
  productGroup,
  productSearchOptions,
  recipeBookType,
  storageUnit,
  timeUnit,
} from './types';

export const esLocale: Locales = {
  [commons.LOADING]: 'Cargando',
  [commons.ACCEPT]: 'Aceptar',
  [commons.ADD]: 'Agregar',
  [commons.FILTERS]: 'Filtros',
  [commons.RETURN]: 'Regresar',
  [commons.FINISH]: 'Finalizar',
  [commons.START]: 'Iniciar',
  [commons.FINISHED]: 'Finalizado',
  [commons.NAME]: 'Nombre',
  [commons.SKU]: 'SKU',
  [commons.STATUS]: 'Estatus',
  [commons.GROUP]: 'Grupo',
  [commons.STEP]: 'Paso',
  [commons.CHECKPOINT]: 'Check point',
  [commons.DOES_NOT_APPLY]: 'No aplica',
  [commons.DETAILS]: 'Más detalles',
  [commons.CATEGORY]: 'Categoría',
  [commons.CATEGORIES]: 'Categorías',
  [commons.STORES_IN]: 'Tiendas en',
  [commons.THERE_IS_NO_DATA_TO_DISPLAY]: 'No hay datos para mostrar',
  [commons.SEARCH_FOR_INPUTS_PRODUCTS_OR_PROTOCOL]: 'Busca por insumos, productos o protocolo',
  [commons.SWITCHING_INPUTS_ON_AND_OFF]: 'Prendidos y apagados de insumos',
  [commons.SELECT_A_KITCHEN]: 'Seleccione una cocina',
  [commons.SELECT_THE_KITCHEN_TO_MONITOR]:
    'Seleccione la cocina para monitorear y gestionar insumos, productos y protocolos',
  [commons.ON_AND_OFF]: 'Prendidos y apagados',
  [commons.PRODUCTS_RELATED_TO_THE_SUPPLIE]: 'Productos relacionados con el insumo',
  [commons.BY_PROTOCOLS]: 'Por protocolos',
  [commons.PROTOCOLS]: 'Protocolos',
  [commons.CONTINUE]: 'Continuar',
  [commons.COPY]: 'Copiar',
  [commons.RECEIVE]: 'Recibir',
  [commons.PROGRESS]: 'Progreso',
  [commons.PRODUCT_NAME]: 'Nombre de producto',
  [commons.UNIT]: 'Unidad',
  [commons.UNIT_IN_KDS]: 'Unidad en kds',
  [commons.UNITS]: 'Unidades',
  [commons.ACTIONS]: 'Acciones',
  [commons.INIT_DATE]: 'Fecha Inicio',
  [commons.FINAL_DATE]: 'Fecha Fin',
  [commons.SEARCH]: 'Buscar',
  [commons.EXPORT]: 'Exportar',
  [commons.CANCEL]: 'Cancelar',
  [commons.DELETE]: 'Eliminar',
  [commons.SAVE]: 'Guardar',
  [commons.SPECIFICATIONS]: 'Especificaciones',
  [commons.BACK]: 'Volver',
  [commons.COUNTRIES]: 'Paises',
  [commons.COUNTRY]: 'País',
  [commons.TYPE]: 'Tipo',
  [commons.SELECT_FILE]: 'Seleccionar archivo',
  [commons.KITCHENS]: 'Cocinas',
  [commons.KITCHEN]: 'Cocina',
  [commons.QUANTITY]: 'Cantidad',
  [commons.GROUPS]: 'Grupos',
  [commons.TO]: 'a',
  [commons.IT_APPEARS_IN_THE_RECIPE_OF_THE]: 'Aparece en la receta del',
  [commons.THE_ITEM]: 'El item',
  [commons.FOR_THIS_REASON]: 'Por esta razón',
  [commons.IT_APPEARS_IN_THE_KITCHEN]: 'Aparece en la cocina',
  [commons.ON_]: 'Encendido',
  [commons.OFF_]: 'Apagado',
  [commons.BY_BRAND]: 'Por marca',
  [commons.ALL_TYPES]: 'Todos los tipos',
  [commons.STORES_AFFECTED_BY_THE_PRODUCT]: 'Tiendas afectadas por el producto',
  [commons.TIME]: 'Tiempo',
  [commons.ENABLED]: 'Activado',
  [commons.DISABLED]: 'Desactivado',
  [commons.QUANTITY_IN_KDS]: 'Cantidad en kds',
  [commons.LAST_MODIFIED]: 'Última Modificación',
  [commons.LAST_UPDATE]: 'Última actualización',
  [commons.DETAIL_OF_THE_REASON]: 'Detalle del motivo',
  [commons.REASON]: 'Motivo',
  [commons.PRIORITY_STATUS]: 'Estado prioritario',
  [commons.PRIORITY_TURN_OFF]: 'Apagado prioritario',
  [commons.PRIORITY_TURN_ON]: 'Prendido prioritario',
  [commons.PROTOCOL]: 'Protocolo',
  [commons.OFF]: 'Apagados',
  [commons.ON]: 'Prendidos',
  [commons.PRODUCTS_TURNED_OFF]: 'Productos apagados',
  [commons.DATETIME]: 'Fecha / Hora',
  [commons.EMPTY_RESULT]: 'No hay resultados',
  [commons.RECIPE_BOOK]: 'Recetario',
  [commons.SELECT_COUNTRY]: 'Seleccionar país',
  [commons.PHOTO]: 'Foto',
  [commons.ITEM]: 'nombre',
  [commons.RESULT_SEARCH]: 'Resultados de la búsqueda de',
  [commons.OF]: 'de',
  [commons.FOR]: 'para',
  [commons.IN]: 'en',
  [commons.CORRECT]: 'Correcto',
  [commons.ERROR]: 'Error',
  [commons.CITY]: 'Ciudad',
  [commons.WITHOUT_OPTIONS]: 'No hay opciones',
  [commons.SELECT]: 'seleccione',
  [commons.SELECT_ALL]: 'Seleccionar todo',
  [commons.LOADING_PROTOCOLS]: 'Cargando protocolos',
  [commons.SELECT_CITY]: 'Seleccionar ciudad',
  [commons.SELECT_PLATFORM]: 'Selecionar plataforma',
  [commons.PLATFORM]: 'Plataforma',
  [commons.PLATFORMS]: 'Plataformas',
  [commons.MENU]: 'Menú',
  [commons.BRAND]: 'Marca',
  [commons.BRANDS]: 'Marcas',
  [commons.PRODUCT_TYPE]: 'Tipo de Producto',
  [commons.ASSEMBLED_PRODUCTION_IN]: 'Producción ensamblada en',
  [commons.SELECT_PAGE]: 'Seleccione una hoja',
  [commons.DISABLE]: 'Deshabilitar',
  [commons.PRODUCT]: 'Producto',
  [commons.PRODUCTS]: 'Productos',
  [commons.SELECT_BRAND]: 'Selecciona marcas de las que deseas conocer el reporte de productos',
  [commons.CURRENT_INVENTORY]: 'Inventario actual',
  [commons.SUPPLIES]: 'Insumos',
  [commons.SUPPLY]: 'Insumo',
  [commons.UNIT_OF_MEASUREMENT]: 'Unidad de medida',
  [commons.PRODUCTION_PERFORMANCE]: 'Rendimiento de la producción',
  [commons.STATE]: 'Estado',
  [commons.STATION]: 'Estación',
  [commons.USEFUL_LIFE]: 'Vida útil',
  [commons.ASSEMBLY]: 'Ensamble',
  [commons.INVENTORY_GROUP]: 'Grupo inventario',
  [commons.IVA_MAJOR_VALUE]: 'Iva mayor valor',
  [commons.TAX_GROUP_CODE]: 'Impuestos productos terminados',
  [commons.IGV]: 'IGV',
  [commons.FINISHED_PRODUCTS]: 'Productos Terminados',
  [commons.SUPPLIES_OR_PRODUCTIONS]: 'Insumos o Producciones',
  [commons.SHUTDOWN_DUE_TO_PROTOCOLS]: 'Apagado por protocolos',
  [commons.SHUTDOWN_BY_STOCKOUTS]: 'Apagado por Stockouts',
  [commons.OVER_RECEIPT_CODE]: 'Código de recepción',
  [commons.GROUPS_ACCOUNTING_RECORD_IVA]: 'Grupo contable iva',
  [commons.STORAGE]: 'Almacenamiento',
  [commons.NCM_CODE]: 'Código NCM',
  [commons.SHOW_AS_COMBO]: 'Visible como combo',
  [commons.SHOW_AS_TOPPING]: 'Visible como topping',
  [commons.IS_VARIABLE_COMBO]: 'Es combo variable',
  [commons.HIDDEN]: 'Oculto',
  [commons.RECIPE_IN_PRINTED_ORDER]: 'Receta en comanda impresa',
  [commons.IS_TOPPING]: 'Es topping',
  [commons.CATEGORIZATION]: 'Categorización',
  [commons.MANDATORY]: 'Obligatorio',
  [commons.EDIT]: 'Editar',
  [commons.STORES]: 'Tiendas',
  [commons.STORE]: 'Tienda',
  [commons.ASSIGN_MODIFIER]: 'Asignar modifiers',
  [commons.ADD_INSTRUCTIONS]: 'Agregar instrucciones',
  [commons.UNITS_ABBREVIATION]: 'und',
  [commons.OTHERS]: 'Otros',
  [commons.WEIGHT]: 'Peso',
  [commons.EXIT]: 'Salir',
  [commons.YES]: 'Sí',
  [commons.NO]: 'No',
  [commons.CURRENT]: 'Actual',
  [commons.NEW]: 'Nuevo',
  [commons.DIFFERENCE]: 'Diferencia',
  [commons.NEW_QUANTITY]: 'Nueva Cantidad',
  [commons.ULTRAPROCESSED_TAX]: 'Impuesto ultraprocesado',
  [commons.ADD_PRODUCT]: 'Agregar producto',
  [commons.PRODUCTS_THAT_CAN_REPLACE_IT]: 'Productos que lo pueden reemplazar',
  [commons.START_TYPING_TO_SEARCH]: 'Empieza a escribir para buscar',
  [commons.NO_MATCH_FOUND]: 'No se encontró ninguna coincidencia',

  [recipeBookType.INSTRUCTIONS_FOR_PREPARATION]: 'Instrucciones de preparación',
  [recipeBookType.ADD_LIFE_CYCLE]: 'Agregar vida útil',
  [recipeBookType.LIFE_CYCLE]: 'Vida útil',
  [recipeBookType.STORAGE_TYPE]: 'Tipo de almacenamiento',
  [recipeBookType.RECORD_CARD]: 'Ver Detalle',
  [recipeBookType.SEARCH_PRODUCTS]: 'Busca productos, producciones e insumos',
  [recipeBookType.PRODUCTS_HAS_CONTAINS]: 'productos que contienen',
  [recipeBookType.TO_SEE_PRODUCTS]: 'para ver productos',
  [recipeBookType.CREATE_NEW_ITEM]: 'Crear nuevo item',
  [recipeBookType.DOWNLOAD_RECIPE]: 'Descargar recetas',
  [recipeBookType.ENABLE_ITEM]: 'Cambiar estado',
  [recipeBookType.ENABLE]: 'Habilitar',
  [recipeBookType.CAN_NOT]: 'No se puede',
  [recipeBookType.CAN_NOT_DISABLE]: 'No se puede deshabilitar',
  [recipeBookType.CONFIRM_DISABLE]: '¿Seguro deseas deshabilitar el item?',
  [recipeBookType.CONFIRM_DISABLE_TEXT]: 'Si deshabilitas el item',
  [recipeBookType.CAN_NOT_VIEW]: 'no se podría visualizar en el recetario y tampoco en las recetas',
  [recipeBookType.I_SURE]: 'Estoy seguro',
  [recipeBookType.CONFIRM_ENABLE]: '¿Seguro deseas habilitar el item?',
  [recipeBookType.CONFIRM_ENABLE_TEXT]: 'Si habilitas el item',
  [recipeBookType.CAN_VIEW]: 'se podría visualizar en el recetario y en las recetas',
  [recipeBookType.CREATE_RECIPE]: 'Crear Receta',
  [recipeBookType.HAS_OPERATOR]: 'Necesita una persona',
  [recipeBookType.MACHINE_REQUIRED]: 'Máquina necesaria',
  [recipeBookType.SELECT_MACHINE]: ' Selecciona la máquina',
  [recipeBookType.STEP_MACHINE]: 'Máquina para el paso',
  [recipeBookType.DETAIL_CHECKPOINT]: 'Detalla el checkpoint',
  [recipeBookType.MESSAGE_SELECT_COUNTRY_PRODUCT_TYPE]:
    'Seleccione el país y el tipo de producto para crear la receta',
  [recipeBookType.APPEARANCE]: 'Aspecto',
  [recipeBookType.COLOR]: 'Color',
  [recipeBookType.SMELL]: 'Olor',
  [recipeBookType.TASTE]: 'Sabor',
  [recipeBookType.TEXTURE]: 'Textura',
  [recipeBookType.VISCOSITY]: 'Viscosidad',
  [recipeBookType.ENTER_CHARACTERISTICS_OF]: 'Ingresa las características de',
  [recipeBookType.ENTER_THE]: 'Ingresa las',
  [recipeBookType.SENSORY_CHARACTERISTICS]: 'Organolépticos',
  [recipeBookType.UNITY_PER_PACKAGE]: 'Unidad por empaque',
  [recipeBookType.GENERAL]: 'General',
  [recipeBookType.UNITY_PER_WEIGHT]: 'Peso por Unidad',
  [recipeBookType.PACKING_TEMPERATURE]: 'Temperatura del producto al empacar',
  [recipeBookType.PRODUCT_TEMPERATURE]: 'Temperatura del producto',
  [recipeBookType.ENTER_PRODUCT_TEMPERATURE]: 'Ingresa la temperatura al empacar',
  [recipeBookType.PROTEIN]: 'Proteina',
  [recipeBookType.VINAIGRETTE]: 'Vinagreta Salsa',
  [recipeBookType.SAUCES]: 'Salsas',
  [recipeBookType.GRAINS]: 'Granos',
  [recipeBookType.BAKING]: 'Postres',
  [recipeBookType.BASE]: 'Base',
  [recipeBookType.DRINK]: 'Bebida',
  [recipeBookType.PREPARED_DRINK]: 'Bebida Preparada',
  [recipeBookType.COVERINGS]: 'Cubiertos',
  [recipeBookType.DONATION]: 'Donacion',
  [recipeBookType.PACKAGING]: 'Empaque',
  [recipeBookType.BAKERY]: 'Panadería',
  [recipeBookType.MAIN_DISH]: 'Plato Fuerte',
  [recipeBookType.SIDES]: 'Porciones Aparte',
  [recipeBookType.DESSERTS]: 'Postres',
  [recipeBookType.SEASONAL_SAUCES]: 'Salsas Estacion',
  [recipeBookType.TOPPING]: 'Topping',
  [recipeBookType.BREADMAKING]: 'Panaderia',
  [recipeBookType.SUBPRODUCTION]: 'Subproduccion',
  [recipeBookType.CP_STATION]: 'Estación cp',
  [recipeBookType.KITCHEN_STATION]: 'Estación de cocina',
  [recipeBookType.VACUUM_PACKING]: 'empaque al vacio',

  [recipeBookType.HOT]: 'Caliente',
  [recipeBookType.FRYING]: 'Fritura',
  [recipeBookType.MIX]: 'Mixta',
  [recipeBookType.COLD]: 'Fria',
  [recipeBookType.BAKERY_PASTRY_SHOP]: 'Panaderia/Pasteleria',
  [recipeBookType.STEP_DURATION]: 'Duración del paso',
  [recipeBookType.DURATION]: 'Duración',
  [recipeBookType.SHRINKAGE_PERCENTAGE]: 'Porcentaje de mermas',
  [recipeBookType.PERCENTAGE]: 'Porcentaje',
  [recipeBookType.ENTER_THE_PERCENTAGE_OF_SHRINKAGE_FOR_ALL_CITIES]:
    'Ingrese el porcentaje de mermas para todas las ciudades',
  [recipeBookType.FACTOR]: 'Factor de Conversión',
  [recipeBookType.SELECT_SUPPLY]: 'Seleccionar insumo',
  [recipeBookType.REPLACE_RECIPE_TITLE]: 'Reemplazar en recetas',
  [recipeBookType.SELECT_SUPPLY_MODAL_TITLE]: 'Seleccionar nuevo insumo',
  [recipeBookType.UPDATE_DIFFERENCE_MESSAGE]: 'Se ajustara la receta con {{quantity}} {{unit}}',
  [recipeBookType.UPDATE_FACTOR_MESSAGE]:
    '{{factor}} {{unit}} de {{name}} equivale a 1 {{selectedProductUnit}} de {{selectedProductName}}',
  [recipeBookType.CALCULATE_WEIGHT_DIVISION]: '¿Calcular división de gramaje?',
  [recipeBookType.PRODUCT_SKU_WARNING]:
    'Ya existe un producto con el SKU {{currentSku}}, este producto quedará con el SKU {{newSku}}.',
  [recipeBookType.PRODUCT_NAME_EXISTS]: 'Ya existe un producto con ese nombre',
  [recipeBookType.MASSIVE_CHANGES_IN_RECIPES]: 'Cambios masivos en recetas',

  [menuType.DEFAULT_ERROR_MSG]: 'Se produjo un error al cargar el menu',
  [menuType.STARTED_LOADING_MENU]: 'Se inició la carga del menú',
  [menuType.UPLOAD_MENU_TO_VIEW]: 'Sube el menú que deseas visualizar',
  [menuType.UPLOAD_MENU]: 'Subir Menú',

  [menuCreator.ADD_AISLE]: 'Agregar Pasillo',
  [menuCreator.PUBLISH_MENU]: 'Publicar Menu',
  [menuCreator.SAVE_DRAFT]: 'Guardar Borrador',
  [menuCreator.DRAFT]: 'Borrador',
  [menuCreator.SETTING]: 'Configuración',
  [menuCreator.MENU]: 'Menú',
  [menuCreator.MENUS]: 'Menús',
  [menuCreator.SEARCH_MENU]: 'Buscar menú',
  [menuCreator.SEARCH_COUNTRY]: 'Buscar país',
  [menuCreator.CHOOSE_COUNTRY]: 'Escoge un país para ver los menús de ese país',
  [menuCreator.CREATE_NEW_MENU]: 'Crear nuevo menú',
  [menuCreator.MENU_NAME]: 'Nombre del menú',
  [menuCreator.BRAND]: 'Marca',
  [menuCreator.STORES]: 'Tiendas',
  [menuCreator.LAST_UPDATE]: 'Última actualización',
  [menuCreator.BY]: 'por',
  [menuCreator.ACTIONS]: 'Acciones',
  [menuCreator.LIBRARY]: 'Librería de plantillas',
  [menuCreator.SAVE_AISLE]: 'Guardar Pasillo',
  [menuCreator.ADD_MENU_ITEM]: 'Agregar Menu Item',
  [menuCreator.AISLE_NAME]: 'Nombre del pasillo',
  [menuCreator.SAVE_MENU_ITEM]: 'Guardar Menu Item',
  [menuCreator.SAVE_MODIFIER_TEMPLATE]: 'Guardar como plantilla',
  [menuCreator.ADD_MODIFIER_GROUP]: 'Agregar Grupo Modificador',
  [menuCreator.PLATFORM_DESCRIPTION]: 'Descripción en plataforma',
  [menuCreator.PLATFORM_NAME]: 'Nombre en plataforma',
  [menuCreator.PRICE]: 'Precio',
  [menuCreator.RECIPE_BOOK_NAME]: 'Nombre en recetario',
  [menuCreator.CHOOSE_IMAGE]: 'Escoge la foto',
  [menuCreator.DELETE_MODAL_TITLE]: '¿Deseas eliminar este pasillo?',
  [menuCreator.DELETE_MODAL_TEXT]: 'Si deseas eliminarlo haz click en “Eliminar”',
  [menuCreator.DELETE_MODAL_CANCEL]: 'Cancelar',
  [menuCreator.DELETE_MODAL_ACTION]: 'Eliminar',
  [menuCreator.MENU_MANAGER_TITLE]: 'Gestor de menús',
  [menuCreator.CREATE_MENU_MODAL_TITLE]: 'Crea un nuevo menú',
  [menuCreator.COPY_MENU_MODAL_ACTION]: 'Copiar Menu',
  [menuCreator.COPY_MENU_MODAL_LABEL]: 'Ingresa el nombre del menú',
  [menuCreator.COPY_MENU_MODAL_TITLE]: ' Ingresa el nombre de la copia',
  [menuCreator.CREATE_MENU_MODAL_TEXT]: 'Ingresa los datos necesarios para crear el menú',
  [menuCreator.CREATE_MENU_MODAL_NAME]: 'Nombre del menú',
  [menuCreator.CREATE_MENU_MODAL_CANCEL]: 'Cancelar',
  [menuCreator.CREATE_MENU_MODAL_ACTION]: 'Crear',
  [menuCreator.LOADING_COPY_MENU_TITLE]: '¡Algo se está cocinando!',
  [menuCreator.LOADING_COPY_MENU_DETAIL]: 'En unos segundos estará completo tu menú',
  [menuCreator.LOADING_COPY_MENU_BUTTON]: 'VOLVER A LA TABLA DE MENUS',
  [menuCreator.DELETE_MENU_ITEM_MODAL_TITLE]: '¿Deseas eliminar este menu item?',
  [menuCreator.DELETE_MENU_ITEM_MODAL_TEXT]: 'Si deseas eliminarlo haz click en “Eliminar”',
  [menuCreator.MENU_ITEMS]: 'Menu Items',
  [menuCreator.MENU_ITEM]: 'Menu Item',
  [menuCreator.ENTER_AISLE_NAME]: 'Ingresa el nombre del pasillo',
  [menuCreator.SEARCH_SKU_NAME]: 'Busca por SKU o nombre',
  [menuCreator.ENTER_PLATFORM_NAME]: 'Ingresa el nombre en plataforma',
  [menuCreator.ENTER_PRICE]: 'Ingresa el precio',
  [menuCreator.ENTER_DESCRIPTION]: 'Ingresa la descripción',
  [menuCreator.THERE_IS_ALREADY_A_GROUP_WITH_THIS_NAME]: 'Ya existe un grupo con este nombre',
  [menuCreator.THE_VALUE_CANNOT_BE_NEGATIVE]: 'El valor no puede ser negativo',
  [menuCreator.MODIFIER_GROUP_NOT_FOUND]: 'Grupo de modificadores no encontrado',
  [menuCreator.ENTER_A_NAME_FOR_THE_NEW_GROUP]:
    'Ingresa un nombre para el grupo de modificadores nuevo',
  [menuCreator.FILL_IN_THE_REQUIRED_DATA_IN_THE_MODIFIERS]:
    'Completa los datos obligatorios en los modificadores',
  [menuCreator.A_PRODUCT_WITH_THIS_SKU_ALREADY_EXISTS_IN_THE_GROUP]:
    'Un producto con este SKU ya existe en el grupo',
  [menuCreator.MODIFIER_GROUP]: 'Grupo modificadores',
  [menuCreator.DELETE_MODIFIER_GROUP]: '¿Deseas eliminar este grupo de modificadores?',
  [menuCreator.DELETE_MODIFIER]: '¿Deseas eliminar este modificador?',
  [menuCreator.MODIFIER_GROUP_NAME]: 'Nombre del grupo de modificadores',
  [menuCreator.ENTER_THE_GROUP_NAME]: 'Ingresa el nombre del grupo',
  [menuCreator.MINIMUM]: 'Mínimo',
  [menuCreator.MAXIMUM]: 'Máximo',
  [menuCreator.OPTIONAL]: 'Opcional',
  [menuCreator.PREVIOUSLY_CREATED]: 'creados previamente',
  [menuCreator.DRAG_MODIFIERS]: 'Arrastra a esta sección los modificadores',
  [menuCreator.CREATE_A_NEW_MODIFIER]: 'Crear un modificador nuevo',
  [menuCreator.ADD_MODIFIERS]: 'Agregar Modificadores',
  [menuCreator.SAVE_GROUP_MODIFIERS]: 'Guardar Grupo Modificadores',
  [menuCreator.EDIT_GROUP_MODIFIERS]: 'Editar Grupo de Modificadores',
  [menuCreator.MODIFIER_GROUPS]: 'Grupos de Modificadores',
  [menuCreator.DRAG_MODIFIERS_GROUPS]: 'Arrastra a esta sección los grupos de modificadores',
  [menuCreator.CREATE_A_NEW_GROUP]: 'Crear un grupo nuevo',
  [menuCreator.ADD_MODIFIER_GROUPS]: 'Agregar Grupos de Modificadores',
  [menuCreator.ADD_THE_GROUPS]: 'Agrega los Grupos de',
  [menuCreator.SHOW_PUBLISHED]: 'Ver publicado',
  [menuCreator.MODIFIERS]: 'Modificadores',
  [menuCreator.ADD_THE_MODIFIERS]: 'Agrega los Modificadores',
  [menuCreator.DESCRIPTION]: 'Descripción',
  [menuCreator.EDIT_MENU]: 'Editar menú',
  [menuCreator.VIEW_MENU]: 'Ver menú',
  [menuCreator.SAVE_SETTINGS]: 'Guardar Configuración',
  [menuCreator.NONE_RESPONSE]: 'Sin Respuesta',
  [menuCreator.SEND]: 'Enviado',
  [menuCreator.REFUSED]: 'Rechazado',
  [menuCreator.PUBLISHED]: 'Publicado',
  [menuCreator.DATE_TEXT_DRAFT]: 'Última modificación ',
  [menuCreator.RESTORE_PUBLISHED]: 'Restablecer publicado',
  [menuCreator.RESTORE_MODAL_TITLE]: '¿Deseas eliminar el borrador y restablecer el publicado?',
  [menuCreator.RESTORE_MODAL_TEXT]:
    'Al aceptar restablecerlo se eliminarán los cambios que se hayan realizado en el borrador actual',
  [menuCreator.MENU_WITH_CHANGES]: 'Menu con cambios sin publicar',
  [menuCreator.REASSIGN_STORE]: 'Reasignar tienda',
  [menuCreator.SCHEDULE]: 'Horario',
  [menuCreator.STORE_SCHEDULE]: 'Horarios de la tienda',
  [menuCreator.MONDAY]: 'Lunes',
  [menuCreator.TUESDAY]: 'Martes',
  [menuCreator.WEDNESDAY]: 'Miércoles',
  [menuCreator.THURSDAY]: 'Jueves',
  [menuCreator.FRIDAY]: 'Viernes',
  [menuCreator.SATURDAY]: 'Sábado',
  [menuCreator.SUNDAY]: 'Domingo',
  [menuCreator.MORE_INFO_ONE]: 'Si deseas cambiar algún horario, contacta',
  [menuCreator.MORE_INFO_TWO]: 'al equipo de tech ops',
  [menuCreator.DELETE_MODIFIER_GROUPS]: 'Eliminar grupo de modificadores',
  [menuCreator.MODIFIER_GROUPS_SINGULAR]: 'Grupo de modificadores',
  [menuCreator.CREATE_A_NEW_GROUP_OF_MODIFIERS]: 'Crear un grupo nuevo de modificadores',
  [menuCreator.EDIT_MODIFIER]: 'Editar modificador',
  [menuCreator.DELETE_MODIFIERS]: 'Eliminar modificador',
  [menuCreator.SAVE_MODIFIER]: 'Guardar modificador',
  [menuCreator.ALERT_MAX_VALUE]:
    'El máximo de los modificadores es mayor que el valor máximo del grupo modificador',
  [menuCreator.INTERNAL_NAME_MODIFIER_GROUP]: 'Nombre interno del grupo de modificadores',
  [menuCreator.INTERNAL_NAME]: 'Ingresa el nombre interno',
  [menuCreator.FIND_THE_MODIFIER_GROUP]: 'Busca el grupo de modificadores',
  [menuCreator.WRITE_THE_NAME_OF_THE_GROUP]: 'Escribe el nombre del grupo',
  [menuCreator.FIND_THE_MODIFIERS]: 'Busca los modificadores',
  [menuCreator.WRITE_THE_NAME_OF_THE_MODIFIER]: 'Escribe el nombre del modificador',
  [menuCreator.EDIT_MENU_ITEM]: 'Editar menu item',
  [menuCreator.EDIT_AISLE]: ' Editar Pasillo',
  [menuCreator.SELECTED]: 'Seleccionaste',
  [menuCreator.ALERT_NO_CHILDREN]: 'Agrega al menos un modificador',
  [menuCreator.MAX]: 'Max',
  [menuCreator.MIN]: 'Min',
  [menuCreator.THERE_ARE_NO_RESULTS_FOR]: 'No hay resultados para',
  [menuCreator.CITY_ZONE]: 'Ciudad / Zona',
  [menuCreator.STAY_IN_MENU]: 'Permanecer en el menú',
  [menuCreator.BACK_TO_BEGINNING]: 'Volver al inicio',
  [menuCreator.THE_MENU]: 'El menú',
  [menuCreator.SAVED_MENU_TITLE]: 'ha sido guardado como borrador',
  [menuCreator.AFTER_PUBLISH_MENU_TITLE]: 'ha sido publicado',
  [menuCreator.SAVED_MENU_TEXT]: 'Consulta el estado del menú en la tabla principal del gestor',
  [menuCreator.PUBLISH_MENU_TITLE]: '¿Deseas publicar, {{menuName}}?',
  [menuCreator.ALL_GROUPS]: 'Todos los grupos',
  [menuCreator.ALL_BRANDS]: 'Todas las marcas',
  [menuCreator.ALL_CATEGORIES]: 'Todas las categorias',
  [menuCreator.SELECT_COUNTRY]: 'Seleccione un pais',
  [menuCreator.PUBLISH_MENU_TEXT]:
    'Al hacer click en “Publicar” se visualizará este menú nuevo en las tiendas',
  [menuCreator.PRICE_CANNOT_BE_0]: 'El precio no puede ser 0',
  [menuCreator.CANNOT_REPEAT_A_MENU_ITEM]: 'No se puede repetir un menú item en el menú',
  [menuCreator.EXCEEDED_THE_MAXIMUM_NUMBER_OF_CHARACTERS_FOR_RAPPI]:
    'Superó la cantidad máxima de caracteres para Rappi',
  [menuCreator.MINIMUM_CAN_BE_3_CHARACTERS]: 'Mínimo pueden ser 3 caracteres',
  [menuCreator.YOU_HAVE_EXCEEDED_THE_MAXIMUM_NUMBER_OF_CHARACTERS_ALLOWED]:
    'Superaste el máximo de caracteres permitidos',
  [menuCreator.AND_YOU_ARE_AT]: 'y estás a',
  [menuCreator.CHARACTERS_REACHING_IFOOD_LIMIT]: 'caracteres de alcanzar el límite de iFood',
  [menuCreator.MISSING]: 'Faltan',
  [menuCreator.CHARACTERS_TO_REACH_THE_MAXIMUM_ALLOWED]:
    'caracteres para alcanzar el máximo permitido',
  [menuCreator.THERE_IS_ALREADY_A_MENU_WITH_THAT_NAME]: 'Ya existe un menú con ese nombre',
  [menuCreator.YOU_HAVE_NOT_SAVED_ALL_THE_MENU_ITEMS]: 'No has guardado todos los menu items',
  [menuCreator.YOU_HAVE_NOT_SAVED_ALL_MODIFIER_GROUPS_IN_THE_MENU_ITEM]:
    'No has guardado todos los grupos de modificadores en los menu items',
  [menuCreator.MENU_COPIED_SUCCESSFULLY]: 'Menú copiado satisfactoriamente',
  [menuCreator.THIS_MENU_COULD_NOT_BE_ASSIGNED_TO_STORES]:
    'Este menú no se pudo asignar a las tiendas',
  [menuCreator.NUMBER_OF_CHARACTERS_DOES_NOT_REACH_THE_MINIMUM]:
    'Cantidad de caracteres no alcanza el mínimo',
  [menuCreator.NUMBER_OF_CHARACTERS_EXCEEDS_THE_MAXIMUM]: 'Cantidad de caracteres supera el máximo',
  [menuCreator.IN_THE_HALLWAY_NAME_FIELD]: 'En el campo nombre pasillo',
  [menuCreator.IN_THE_MODIFIER_GROUP_NAME_FIELD]: 'En el campo nombre grupo modificadores',
  [menuCreator.IN_THE_MENU_ITEM_DESCRIPTION]: 'En la descripción del menu item',
  [menuCreator.IN_THE_MODIFIER_DESCRIPTION_FIELD]: 'En el campo descripción del modificador',
  [menuCreator.IN_THE_NAME_OF_THE_MENU_ITEM]: 'En el nombre del menu item',
  [menuCreator.IN_THE_MODIFIER_NAME_FIELD]: 'En el campo nombre modificador',
  [menuCreator.THE_NUMBER_OF_DIGITS_IN_THE_PRICE_EXCEEDS_THE_MAXIMUM_ALLOWED]:
    'El número de dígitos en el precio supera el máximo permitido de',
  [menuCreator.IN_THE_PRICE_FIELD_OF_THE_MENU_ITEM]: 'en el campo precio del menu item',
  [menuCreator.IN_THE_AISLE]: 'En el pasillo',
  [menuCreator.ALERT_VALUE_OF_THE_MODIFIERS]:
    'El valor máximo de los modificadores no puede ser mayor al valor máximo del grupo modificador',
  [menuCreator.AISLE]: 'Pasillo',
  [menuCreator.PRODUCT]: 'Producto',
  [menuCreator.THERE_IS_ALREADY_A_HALLWAY_WITH_THAT_NAME]: 'Ya existe un pasillo con ese nombre',
  [menuCreator.CANNOT_SAVE_MODIFIER_GROUP]: 'No se puede guardar el grupo modificador',
  [menuCreator.CANNOT_SAVE_MODIFIER]: 'No se puede guardar el modificador',
  [menuCreator.CANNOT_SAVE_MODIFIER_GROUP_DETAIL]:
    'Ya existe un grupo de modificadores con ese nombre, cambia el nombre si aun deseas guardarlo.',
  [menuCreator.CANNOT_SAVE_MODIFIER_DETAIL]:
    'Ya existe un modificador con ese nombre, cambia el nombre si aun deseas guardarlo.',
  [menuCreator.SAVED_COMPONENT_FRAME]: 'ha sido guardado como plantilla',
  [menuCreator.UPDATED_COMPONENT_FRAME]: 'ha sido actualizado',
  [menuCreator.SAVED_MODIFIER_GROUP_DETAIL]:
    'El grupo de modificadores fue guardado, ya puedes consultarlo en la librería de plantillas.',

  [menuCreator.SAVED_MODIFIER_DETAIL]:
    'El modificador fue guardado, ya puedes consultarlo en la librería de plantillas.',
  [menuCreator.RETURN_TO_EDIT]: 'Regresar a editar',
  [menuCreator.SEE_LESS]: 'Ver más',
  [menuCreator.SEE_MORE]: 'Ver menos',
  [menuCreator.MINIMUM_VALUE_CANNOT_BE_GREATER_THAN_THE_MAXIMUM_VALUE]:
    'El valor mínimo no puede ser mayor al valor máximo',
  [menuCreator.SAVE_NAME]: 'Guardar nombre',
  [menuCreator.WRITE_THE_NAME_OF_THE_MENU_ITEM]: 'Escribe el nombre del menu item',
  [menuCreator.FIND_THE_MENU_ITEMS]: 'Busca el menu item',
  [menuCreator.CREATE_A_MENU_ITEM]: 'Crear un menu item nuevo',
  [menuCreator.YOU_CAN_NOW_CONSULT_THE_SAVED_MENU_ITEM_IN_THE_TEMPLATE_LIBRARY]:
    'El menu item guardado ya puedes consultarlo en la librería de templates.',
  [menuCreator.CANNOT_SAVE_THIS_MENU_ITEM]: 'No se puede guardar este menu item',
  [menuCreator.TEMPLATE_FOR_THAT_MENU_ITEM_ALREADY_EXISTS]:
    'Ya existe una plantilla de ese menu item, cambia el producto si aun deseas guardarlo.',
  [menuCreator.ADD_MENU_ITEMS]: 'Agrega los menu items',
  [menuCreator.DRAG_MENU_ITEMS]: 'Arrastra a esta sección los menu items',
  [menuCreator.COMPLETE_MENU_ITEM]: 'Guarda el menu item antes de continuar',
  [menuCreator.COLLAPSE_ALL_AISLES]: 'Contraer todos los pasillos',
  [menuCreator.EXPAND_ALL_AISLES]: 'Expandir todos los pasillos',
  [menuCreator.CANNOT_BE_DELETED]: 'No se puede eliminar',
  [menuCreator.DELETE_MENU]: 'Eliminar menú',
  [menuCreator.ASSIGNED_MENU]: 'No se puede eliminar este menú porque está asignado a las tiendas:',
  [menuCreator.WANT_TO_DELETE_THIS_MENU]: '¿Seguro que desea eliminar este menú?',
  [menuCreator.SELECT_A_STORE]:
    'Selecciona al menos una tienda y guarda la configuración para poder publicar el menú',
  [menuCreator.SAVE_TEMPLATE]: 'Guardar {{type}} como plantilla',

  [categorization.CATEGORIZATION_BY_CITIES]: 'Categorización por ciudades',

  [menuUploader.PERFECT_FILE]: '¡El archivo está perfecto!',
  [menuUploader.CLICK_TO_UPLOAD]: 'Haz click en “SUBIR MENÚ”',
  [menuUploader.REVIEWING_MENU]: 'Estamos revisando el menú',

  [measureUnits.UN]: 'Unidad',
  [measureUnits.G]: 'Gramos',
  [measureUnits.GR]: 'Gramos',
  [measureUnits.ML]: 'Mililitros',
  [measureUnits.KG]: 'Kilogramos',

  [productForm.DRAFT]: 'En borrador',
  [productForm.PUBLISHED]: 'Publicado',
  [productForm.SAVE_DRAFT]: 'Guardar Borrador',
  [productForm.SAVE_CHANGES]: 'Guardar cambios',
  [productForm.PUBLISH_CHANGES]: 'Publicar cambios',
  [productForm.CREATED]: 'Creado',
  [productForm.EDITED]: 'Editado',
  [productForm.PUBLISH]: 'Publicar',
  [productForm.NCM_CODE_REQUIRED]: 'El código ncm es obligatorio',
  [productForm.COMPLETE_FORM]: 'Ingrese todos los datos',
  [productForm.AVOID_NEGATIVE_INVETORY]: 'Evitar inventario negativo',
  [productForm.SHOW_RECIPE_ON_STATION]: 'Receta visible en estación',

  // [timeUnit.EMPTY]: '',
  [timeUnit.HOUR]: 'Horas',
  [timeUnit.DAY]: 'Días',
  [timeUnit.WEEK]: 'Semanas',
  // [timeUnit.MONTH]: 'Meses',

  //[storageUnit.EMPTY]: '',
  [storageUnit.FREEZING]: 'Congelación ( -18ºC )',
  [storageUnit.REFRIGERATION]: 'Refrigeración (1 ºC - 5ºC)',
  [storageUnit.ROOM_TEMPERATURE]: 'Ambiente (15ºC - 30ºC)',

  [machineList.STOVE]: 'Estufa',
  [machineList.UNOX]: 'Unox',
  [machineList.GRIDDLE]: 'Plancha',
  [machineList.DEEP_FRYER]: 'Freidora',
  [machineList.PIZZA_OVEN]: 'Horno Pizza',
  [machineList.SEALER]: 'Selladora',
  [machineList.INDUSTRIAL_BLENDER]: 'Licuadora Industrial',
  [machineList.MIXER]: 'Batidora',
  [machineList.DOUGH_MIXER]: 'Amasadora',
  [machineList.IMMERSION_BLENDER]: 'Brazo inmersión',
  [machineList.ELECTRIC_MILL]: 'Molino eléctrico',
  [machineList.LANG_CINNABON_OVEN]: 'Horno Lang Cinnabon',
  [machineList.COOKING_KETTLE]: 'Marmita',
  [machineList.FERMENTER]: 'Fermentadora',
  [machineList.DOUGH_SHEETER]: 'Laminadora',
  [machineList.MIXER_60_LT]: 'Batidora 60Lt',
  [machineList.BLAST_CHILLER]: 'Abatidor',
  [machineList.COOLING]: 'Enfiramiento',
  [machineList.MISE_EN_PLACE]: 'MEP',
  [machineList.PROCESS]: 'Proceso',
  [machineList.PACKAGING]: 'Empaque',

  [info.BRAND]:
    'Acá podrás visualizar la lista de marcas y en que plataformas se encuentra este producto o insumo',
  [info.CATEGORIZATION]:
    'La categorización indica qué insumos son más indispensables en una cocina. Ejemplo: · Tipo AA indispensables en una cocina ya que nos afecta no vender productos terminados de este tipo. · Tipo C es un insumo que no apaga tantos productos terminados, y afecta solo platos con poco volumen y frecuencia de ventas.',
  [info.EDITED]: 'Ha sido editado exitosamente',
  [info.HIDDEN]: 'La cocina no va a poder visualizar',
  [info.SAVED]: 'Se guardaron los cambios de tu borrador',
  [info.PUBLISH]: '¿Estás seguro que deseas publicar los cambios?',
  [info.WARNING_PUBLISH]:
    'Después de que publiques no podrás editar el SKU, te recomendamos que lo dejes en borrador mientras que catálogo realiza el cambio en Intuipos.',
  [info.PRODUCT_TYPE]: 'Tipo de producto terminado.',
  [info.STATION]: 'Estación donde debería salir la comanda del producto.',
  [info.CATALOG]: '[Solo para Catálogo]',
  [info.CATEGORY]: 'Categoría necesaria para contabilidad.',
  [info.HIDE_PRODUCT]: 'Selecciónalo si deseas que por defecto se oculte este producto terminado.',
  [info.HIDE_TOPPING]:
    'Selecciónalo si deseas que por defecto salga como topping vinculado a un plato fuerte.',
  [info.LOADING_PRODUCTS]: 'Cargando productos',
  [info.LOADING_SUPPLIES]: 'Cargando insumos',
  [info.SELECT_BRAND_AND_PLATFORM]:
    'Por favor seleccione una marca y una plataforma para continuar',
  [info.PRODUCTS_NOT_FOUND]: 'No se encontraron productos para',
  [info.EMPTY_RECIPE]: 'Receta vacía',
  [info.INCORRECT_AMOUNT]: 'Cantidad incorrecta',
  [info.ADD_AT_LEAST_ONE_INGREDIENT]: 'Debes agregar al menos un insumo a la receta',
  [info.IS_REQUIRED]: ' es requerido',
  [info.QUANTITY_LESS_THAN_OR_EQUAL_TO_0]:
    'Ningún item de la receta puede llevar cantidad menor o igual a 0',
  [info.ORGANOLEPTIC_MANDATORY]: 'Todos los organolepticos son obligatorios',
  [info.SHELFLIFE_MANDATORY]: 'Ingrese al menos un elemento en vida util',
  [info.SHOW_AS_COMBO]: 'Se mostrará como combo',
  [info.IS_VARIABLE_COMBO]: 'Es combo variable',
  [info.RECIPE_IN_PRINTED_ORDER]: 'La receta se imprimirá en la comanda',

  [productGroup.PRODUCTION]: 'Producción',
  [productGroup.FINISHED_PRODUCT]: 'Producto terminado',
  [productGroup.SUPPLY]: 'Insumo',
  [productGroup.COMBO]: 'Combo',
  [productGroup.GROCERY]: 'Abarrotes',
  [productGroup.DRINK]: 'Bebidas',
  [productGroup.ALCOHOLIC_DRINK]: 'Bebidas alcohólicas',
  [productGroup.MEAT]: 'Carnes',
  [productGroup.BEER]: 'Cervezas',
  [productGroup.CIGARETTES]: 'Cigarrillos',
  [productGroup.UNKNOWN]: 'Desconocido',
  [productGroup.ADJUSTABLE_PACKAGING]: 'Empaques ajustables',
  [productGroup.PACKAGING]: 'Empaques',
  [productGroup.FRUITS]: 'Fruver',
  [productGroup.DAIRY]: 'Lacteos',
  [productGroup.MAINTENANCE]: 'Mantenimiento',
  [productGroup.BAKERY]: 'Panaderia',
  [productGroup.UTENSILS]: 'Utensilios',
  [productGroup.CLEANING]: 'Limpieza',
  [productGroup.CONSUMB]: 'Consumibles',
  [productGroup.MENUITEMS]: 'Menu Items',
  [productGroup.RAWMATRLS]: 'Raw Materials',
  [productGroup.SEMIFINISHED]: 'Semifinished',
  [productGroup.STATIONERY]: 'Papeleria',

  [productSearchOptions.ALL]: 'Todos los casos con',
  [productSearchOptions.RECIPE]: 'Contienen en la receta',

  [cpStationOptoins.HOT]: 'Caliente',
  [cpStationOptoins.FRYING]: 'Fritura',
  [cpStationOptoins.MIX]: 'Mixta',
  [cpStationOptoins.COLD]: 'Fria',
  [cpStationOptoins.BAKERYPASTRY]: 'Panaderia-Pasteleria',
  [cpStationOptoins.PACKAGING]: 'Empaque',
};
