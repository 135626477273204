import { ApiVersion, getHttp } from '../../../utils/http';
import { CountryResponse } from '../entities/Country';

export const getAllCountries = async (): Promise<CountryResponse> => {
  try {
    const response = await getHttp(
      process.env.REACT_APP_BISTRO_KEEPER_URL ?? '',
      ApiVersion.V1,
      `country/all`,
    );
    const data = await response.json();
    return data as CountryResponse;
  } catch (ex: any) {
    console.error(`Error in getAllCountries | ${ex.message}`);
    return { data: [] } as CountryResponse;
  }
};
