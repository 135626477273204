import { AlertColor, SnackbarOrigin } from '@mui/material';
import { BrandSlice } from '../../../core/brand/entities/Brand';
import { City } from '../../../core/city/entities/City';
import { StoreSlice } from '../../../core/stores/entities/Store';
import { Country } from '../../../models/country';
import { StoreRequestError, StoreRequestStatus } from '../../types';

export interface Alert {
  show?: boolean;
  title?: string | null;
  message?: string | null;
  duration?: number | null;
  severity?: AlertColor;
  position?: SnackbarOrigin;
  icon?: React.ReactNode;
  action?: React.ReactNode;
  audioOn?: boolean | null;
}

export interface CitiesState {
  data: City[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface PlatformsState {
  data: string[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface CountriesState {
  data: Country[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface BrandsState {
  data: BrandSlice[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface StoresState {
  data: StoreSlice[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface GlobalState {
  alert: Alert;
  title: string | null;
  countries: CountriesState;
  stores: StoresState;
  cities: CitiesState;
  platforms: PlatformsState;
  brands: BrandsState;
  canSearchOnTopBar: boolean;
  textSearchOnTopBar: string;
}

export const defaultData = {
  data: [],
  status: 'idle',
  error: null,
};

export const initialState: GlobalState = {
  alert: { show: false },
  cities: defaultData as CitiesState,
  brands: defaultData as BrandsState,
  platforms: defaultData as PlatformsState,
  countries: defaultData as CountriesState,
  stores: defaultData as StoresState,
  title: null,
  canSearchOnTopBar: false,
  textSearchOnTopBar: '',
};
